@font-face {
  font-family: "AdobeClean";
  src: local("AdobeClean"), url("./AdobeClean/_ttf/AdobeClean-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AdobeCleanSerif";
  src: local("AdobeCleanSerif"),
    url("./AdobeCleanSerif/AdobeCleanSerif-Regular.otf") format("opentype");
}
