@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@font-face {
  font-family: "AdobeClean";
  src: local("AdobeClean"), url(/static/media/AdobeClean-Regular.b46ffbdc.ttf) format("truetype");
}

@font-face {
  font-family: "AdobeCleanSerif";
  src: local("AdobeCleanSerif"),
    url(/static/media/AdobeCleanSerif-Regular.0a405066.otf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 100%;
  font-smooth: always;
  scroll-behavior: smooth;
  font-family: 'AdobeClean', 'AdobeCleanSerif', 'Poppins', sans-serif;
}
html {
  min-height: 100vh;
}

.appbar .c {
  fill: black;
}

